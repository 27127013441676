/* use smaller paddings <= 750 */
.widget-anchorlinktabs {
  margin-top: 30px;
  margin-bottom: clamp(30px, 5vw, 50px);
}
.widget-anchorlinktabs__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.widget-anchorlinktabs__item__link {
  padding: 10px 15px;
  display: inline-block;
  line-height: 20px;
  text-decoration: none;
  transition: color 300ms, text-shadow 300ms;
  color: var(--bs-primary);
}
.widget-anchorlinktabs__item__link:hover {
  color: #000;
  text-shadow: 0 0 1px #000;
}