/* use smaller paddings <= 750 */
.widget-productfact {
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
}
.widget-productfact__panel {
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 12px 12px 0;
  background-color: #fff;
  max-width: 729px;
}
.widget-productfact__leftpanel {
  flex: none;
  width: clamp(200px, 20vw, 260px);
  min-height: 260px;
  color: #fff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--bs-secondary);
  font-family: var(--font-heading);
  font-weight: 700;
  padding: 24px;
}
.widget-productfact__leftpanel__label {
  font-size: clamp(18px, 2vw, 20px);
  line-height: 110%;
}
.widget-productfact__leftpanel__value {
  font-size: clamp(60px, 8vw, 80px);
  line-height: 110%;
}
.widget-productfact__description {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 24px clamp(24px, 5vw, 50px);
}
.widget-productfact__description > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 650px) {
  .widget-productfact__panel {
    flex-flow: column;
    border-radius: 0 0 12px 12px;
  }
  .widget-productfact__leftpanel {
    width: 100%;
  }
  .widget-productfact__description {
    padding: 20px;
  }
}