/* use smaller paddings <= 750 */
.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
}
html.dompack--busymodal .rpc__busyoverlay {
  display: flex;
}
.rpc__busyoverlay__indicator {
  flex: none;
  font-size: 70px;
  width: 70px;
  height: 70px;
}

.confirmdialog {
  width: 350px;
  padding-top: 20px;
}
.confirmdialog__header {
  text-align: center;
}
.confirmdialog__header h3 {
  margin-bottom: 30px;
}
.confirmdialog__header h3 + .wh-form__fieldgroup {
  margin-top: -20px;
  margin-bottom: 25px;
}
.confirmdialog__header h3 + .wh-form__fieldgroup .wh-form__label {
  font-weight: 400;
}
.confirmdialog__buttons {
  display: flex;
  justify-content: space-between;
}
.confirmdialog__buttons--center {
  justify-content: center;
}

.dekbedacties-dialog {
  min-height: 100px;
  max-height: calc(100vh - 90px);
  overflow: hidden;
  overflow-y: auto;
  padding: 20px;
  max-width: 100%;
  min-width: 300px;
}
.dekbedacties-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dekbedacties-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.dekbedacties-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.dekbedacties-dialog-navigation .dialog__close {
  display: none;
}
.dekbedacties-dialog-navigation .dekbedacties-dialog {
  padding: 5px;
}
.dekbedacties-dialog__title {
  font-weight: 500;
  font-size: 20px;
  padding: 0 50px 15px;
  text-align: center;
}
.dekbedacties-dialog--video {
  width: calc(100vw - 40px);
  max-width: 980px;
}
.dekbedacties-dialog .dekbedacties-dialog__content__scrollarea {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 340px);
}
@media (max-width: 900px) {
  .dekbedacties-dialog {
    max-height: calc(100vh - 10vw);
  }
}
.dekbedacties-dialog__holder {
  border-radius: 6px;
  overflow: visible;
  background: #ffffff;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  transition: transform 300ms, opacity 300ms;
  opacity: 0;
  transform: scale(0.01, 0.01);
}
.dekbedacties-dialog__holder--aftershow {
  opacity: 1;
  transform: scale(1, 1);
}
@media (max-width: 630px) {
  .dekbedacties-dialog {
    max-height: 100vh;
  }
  .dekbedacties-dialog__holder {
    border-radius: 0;
  }
}
.dekbedacties-dialog__modalbg {
  z-index: 1001;
}

.dialog__close {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}