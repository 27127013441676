/* use smaller paddings <= 750 */
.widget-contentlisting {
  margin-bottom: 30px;
}
.widget-contentlisting__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
.widget-contentlisting__item {
  flex: none;
  width: 33.3333%;
  padding-left: 24px;
  margin-bottom: 24px;
}
@media (max-width: 1023px) {
  .widget-contentlisting__item {
    width: 50%;
  }
}
@media (max-width: 700px) {
  .widget-contentlisting__item {
    width: 100%;
  }
}
.widget-contentlisting__item__link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-flow: column;
  transition: box-shadow 300ms;
}
.widget-contentlisting__item__link:hover {
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
}
.widget-contentlisting__item__image {
  flex: 1 1 auto;
  aspect-ratio: 16/9;
}
.widget-contentlisting__item__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 16px 16px 0 0;
}
.widget-contentlisting__item__text {
  padding: 24px;
}
.widget-contentlisting__item__text .button {
  margin-top: 15px;
}