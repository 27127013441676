/* use smaller paddings <= 750 */
.widget-faq {
  margin-bottom: 50px;
  border-top: 1px solid var(--bs-secondary);
}
.widget-faq__item {
  border-bottom: 1px solid var(--bs-secondary);
}
.widget-faq .wh-details__summary {
  padding: 15px 45px 15px 0;
  position: relative;
  z-index: 0;
}
.widget-faq .wh-details__summary h4 {
  line-height: 130%;
  font-size: 18px;
  margin-bottom: 0;
}
.widget-faq .wh-details__summary::after {
  position: absolute;
  display: block !important;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: var(--bs-primary);
  content: "\f067";
}
.widget-faq .wh-details__container__inner {
  padding-bottom: 20px;
}
.widget-faq .wh-details--open > .wh-details__summary::after {
  content: "\f068";
}