/* use smaller paddings <= 750 */
.widget-brands {
  margin-bottom: 30px;
  font-weight: 600;
  font-family: var(--font-heading);
  font-size: clamp(18px, 2.4vw, 24px);
  display: flex;
  align-items: flex-start;
}
.widget-brands__title {
  flex: none;
  padding-right: 24px;
  padding-top: 12px;
}
.widget-brands__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -24px;
  flex: 1 1 auto;
}
.widget-brands__list > li {
  padding-left: 24px;
  margin-bottom: 15px;
}
.widget-brands__brand__link {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}
.widget-brands__brand__logo {
  vertical-align: bottom;
}
@media (max-width: 750px) {
  .widget-brands {
    display: block;
  }
  .widget-brands__title {
    padding: 0 0 12px;
  }
}