/* use smaller paddings <= 750 */
.widget-productmultiplefacts {
  margin-bottom: 50px;
  background-color: var(--bs-light);
  padding-top: 50px;
}
.widget-productmultiplefacts h2 {
  margin-bottom: 40px;
}
.widget-productmultiplefacts .contentcontainer {
  position: relative;
  padding-bottom: 50px;
}
.widget-productmultiplefacts__image {
  position: absolute;
  width: clamp(250px, 40vw, 400px);
  height: auto;
  right: 2%;
  bottom: 0;
  transform: translateY(40%);
}

.widget-productfact + .widget-productmultiplefacts {
  padding-top: 100px;
  margin-top: -80px;
}

@media (min-width: 991px) {
  .productpage-grid .widget-productmultiplefacts .widget-productfact {
    max-width: 55%;
  }
}