/* use smaller paddings <= 750 */
.widget-products h2 {
  margin-bottom: 15px;
}
.widget-products__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 700px) {
  .widget-products__list {
    margin-left: -15px;
  }
}
.widget-products__product {
  flex: none;
  width: calc(25% - 24px);
  margin-left: 24px;
  margin-bottom: 24px;
}
.widget-products__product h2 {
  margin-bottom: 0;
}
.widget-products__product h2 + * {
  margin-top: clamp(10px, 15px, 1.5dvw);
}
@media (max-width: 1050px) {
  .widget-products__product {
    width: calc(33.3333% - 24px);
  }
}
@media (max-width: 700px) {
  .widget-products__product {
    width: calc(50% - 15px);
    margin-left: 15px;
    margin-bottom: 15px;
  }
}
.widget-products__product__link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  transition: box-shadow 300ms;
}
.widget-products__product--animated .widget-products__product__link {
  transition: all 300ms ease-in-out;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  perspective: 1000px;
}
.widget-products__product__backbutton, .widget-products__product__frontbutton {
  font-size: clamp(13px, 16px, 1.6dvw);
  padding: 0 clamp(15px, 24px, 2.4dvw) 5px;
  flex: none;
  display: none;
  align-self: flex-start;
}
@media (hover: hover) and (min-width: 501px) {
  .widget-products__product:hover .widget-products__product__link {
    box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  }
  .widget-products__product--animated:hover .widget-products__product__link {
    transform: rotateY(180deg);
  }
}
@media (hover: none), (max-width: 500px) {
  .widget-products__product__backbutton, .widget-products__product__frontbutton {
    display: inline-flex;
  }
  .widget-products__product--showbackpanel .widget-products__product__link {
    transform: rotateY(180deg);
  }
}
.widget-products__product__front, .widget-products__product__back {
  display: flex;
  flex-flow: column;
  backface-visibility: hidden;
  flex: none;
  width: 100%;
}
.widget-products__product__front {
  position: relative;
}
.widget-products__product__front__discount {
  background-color: var(--bs-red);
  color: #fff;
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 13px;
  line-height: 22px;
  border-radius: 4px;
  padding: 0 10px;
}
.widget-products__product__front__image {
  width: 100%;
  flex: none;
  aspect-ratio: 1/1;
}
.widget-products__product__front__image img {
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-products__product__front__title {
  flex: 1;
  hyphens: auto;
  padding: clamp(15px, 24px, 2.4dvw);
  font-size: clamp(15px, 18px, 1.8dvw);
}
.widget-products__product__front .widget-products__product__footer__button {
  opacity: 0;
  transition: opacity 300ms;
}
.widget-products__product__front:hover .widget-products__product__footer__button {
  opacity: 1;
}
.widget-products__product__back {
  margin-left: -100%;
  transform: rotateY(180deg);
}
.widget-products__product__back__title {
  font-weight: 700;
  font-size: clamp(15px, 18px, 1.8dvw);
  padding: clamp(15px, 24px, 2.4dvw);
  color: var(--bs-secondary);
  hyphens: auto;
}
@media (max-width: 700px) {
  .widget-products__product__back__title {
    display: none;
  }
  .widget-products__product__back__details > li:first-child {
    padding-top: 15px;
    border-radius: 16px 16px 0 0;
  }
}
.widget-products__product__back__details {
  flex: 1 1 auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: 0;
}
.widget-products__product__back__details__item {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 3px clamp(15px, 24px, 2.4dvw);
  line-height: 130%;
  font-size: clamp(13px, 16px, 1.6dvw);
}
.widget-products__product__back__details__item i {
  flex: none;
  display: block;
  width: clamp(20px, 30px, 3dvw);
}
.widget-products__product__back__details__item:first-child {
  padding-top: 15px;
}
.widget-products__product__back__details__item:last-child {
  padding-bottom: 15px;
}
.widget-products__product__back__details__item--positive {
  background-color: var(--bs-light);
}
.widget-products__product__back__details__item--positive i {
  color: var(--bs-success);
}
.widget-products__product__back__details__item--positive + .widget-products__product__back__details__item--negative {
  border-top: 10px solid var(--bs-light);
  padding-top: 15px;
}
.widget-products__product__back__details__item--negative i {
  color: var(--bs-danger);
}
.widget-products__product__brand {
  flex: none;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
.widget-products__product__brand img {
  max-width: calc(100% - 48px);
  height: auto;
  vertical-align: bottom;
}
.widget-products__product__footer {
  flex: none;
  margin-top: 10px;
  padding: 10px clamp(15px, 24px, 2.4dvw);
  background-color: var(--bs-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-products__product__footer__price b {
  font-weight: 600;
  font-style: italic;
  font-size: clamp(18px, 2.6vw, 26px);
  font-family: var(--font-heading);
}
.widget-products__product__footer__button {
  min-width: 50px;
}
.widget-products__product__footer__button .button__icon {
  font-size: 12px;
}
@media (max-width: 400px) {
  .widget-products__product__footer__button .button__icon {
    display: none;
  }
}
@media (max-width: 350px) {
  .widget-products__product__footer {
    flex-flow: column;
    align-items: flex-start;
  }
  .widget-products__product__footer__button {
    margin-top: 5px;
  }
}
.widget-products--withsidewidgets {
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [sidewidgets] 1fr [content-start] 3fr [content-end];
}
@media (max-width: 920px) {
  .widget-products--withsidewidgets {
    grid-template-columns: [sidewidgets] 230px [content-start] 1fr [content-end];
  }
}
@media (max-width: 750px) {
  .widget-products--withsidewidgets {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
  .widget-products--withsidewidgets .widget-products__container {
    width: 100%;
  }
}
.widget-products--withsidewidgets .widget-products__container {
  grid-area: 1/content-start/9999/content-end;
}
.widget-products__filtersbutton {
  display: none;
  margin-bottom: 30px;
}
.widget-products__filtersbutton .button__icon {
  font-size: 15px;
}
@media (max-width: 750px) {
  .widget-products__filtersbutton {
    display: inline-flex;
  }
}
.widget-products > .widget-products__filters, .widget-products__sidewidget {
  grid-area: auto/sidewidgets/auto/content-start;
  padding-right: 24px;
  flex: none;
}
@media (max-width: 750px) {
  .widget-products > .widget-products__filters, .widget-products__sidewidget {
    padding-right: 0;
  }
}
.widget-products__sidewidget {
  margin-bottom: clamp(15px, 2.4vw, 24px);
}
@media (max-width: 750px) {
  .widget-products__sidewidget {
    order: 9;
  }
}
.widget-products__filters__header {
  display: none;
  height: 50px;
  padding-left: clamp(15px, 5vw, 24px);
  min-width: 320px;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid var(--bs-gray-300);
}
.widget-products__filters__header__title {
  font-family: var(--font-heading);
  color: var(--bs-secondary);
  font-weight: 700;
  font-size: 24px;
  flex: 1 1 auto;
  align-self: center;
}
.widget-products__filters__header__closebutton {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  width: 50px;
  color: var(--bs-primary);
  font-size: 24px;
  border-left: 1px solid var(--bs-gray-300);
}
.widget-products__filters__filter {
  margin-bottom: clamp(15px, 2.4vw, 24px);
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: clamp(15px, 2.4vw, 24px);
}
.widget-products__filters__filter h5 {
  margin-bottom: clamp(10px, 1.5vw, 15px);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bs-gray-300);
}
.widget-products__filters .wh-form__pulldown-styled .webshop-categoryfilter__pulldown {
  border-color: #D8D8D8;
  background-color: var(--bs-light);
}
@media (max-width: 750px) {
  .widget-products__filters {
    width: 100%;
    min-width: 0;
    max-width: 0;
    transition: max-width 300ms;
    position: fixed;
    padding: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    overflow: hidden;
  }
  .widget-products__filters--visible {
    max-width: 400px;
  }
  .widget-products__filters--visible::before {
    content: "";
    display: block;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .widget-products__filters__header {
    display: flex;
  }
  .widget-products__filters__scrollcontainer {
    position: relative;
    height: calc(100% - 50px);
    min-width: 320px;
    background-color: #fff;
  }
  .widget-products__filters__form {
    padding: clamp(15px, 5vw, 24px);
  }
}
.widget-products--withsidewidgets .widget-products__product {
  width: calc(33.333% - 24px);
}
@media (max-width: 1050px) {
  .widget-products--withsidewidgets .widget-products__product {
    width: calc(50% - 24px);
  }
}
@media (max-width: 700px) {
  .widget-products--withsidewidgets .widget-products__product {
    width: calc(50% - 15px);
    margin-left: 15px;
    margin-bottom: 15px;
  }
}