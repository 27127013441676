/* use smaller paddings <= 750 */
.widget-imagetext {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
}
.widget-imagetext__image {
  flex: none;
  width: clamp(60px, 17vw, 175px);
  margin-right: clamp(15px, 2vw, 24px);
}
.widget-imagetext__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-imagetext__text {
  flex: 1 1 auto;
}
.widget-imagetext__text > *:last-child {
  margin-bottom: 0;
}