/* use smaller paddings <= 750 */
.widget-ctabanner {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  background-color: var(--bs-light);
  padding: 50px 0;
}
.widget-ctabanner__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget-ctabanner__image, .widget-ctabanner__text {
  flex: none;
  width: calc(50% - 12px);
}
.widget-ctabanner__image {
  text-align: center;
}
.widget-ctabanner__image img {
  width: 100%;
  max-width: 664px;
  height: auto;
  vertical-align: bottom;
}
.widget-ctabanner__text {
  max-width: 560px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  padding: clamp(24px, 5vw, 50px);
}
.widget-ctabanner__text a {
  color: var(--bs-primary);
  text-underline-position: under;
  transition: color 300ms;
}
.widget-ctabanner__text a:hover {
  color: var(--bs-primary-dark);
}
.widget-ctabanner__text > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 750px) {
  .widget-ctabanner__inner {
    flex-flow: column;
  }
  .widget-ctabanner__image, .widget-ctabanner__text {
    width: 100%;
  }
  .widget-ctabanner__image {
    margin-top: 24px;
    order: 2;
  }
}