/* use smaller paddings <= 750 */
.pageheader--productpage {
  padding-bottom: 12vw;
  min-height: 0;
}
.pageheader--productpage::before {
  height: 12vw;
}
@media (min-width: 1380px) {
  .pageheader--productpage {
    padding-bottom: 180px;
  }
  .pageheader--productpage::before {
    height: 180px;
  }
}
@media (max-width: 750px) {
  .pageheader--productpage {
    margin-bottom: 20px;
  }
}

.productpage {
  border-radius: 12px;
}
.productpage__productimages {
  flex: none;
  width: 55%;
  margin-right: 24px;
}
@media (max-width: 750px) {
  .productpage__productimages {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
}
.productpage__productimages__preview, .productpage__productimages__thumbs {
  display: flex;
  justify-content: flex-start;
}
.productpage__productimages__preview__image > img, .productpage__productimages__thumbs__image > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.productpage__productimages__preview {
  border-radius: 8px;
  aspect-ratio: 1/1;
  overflow: hidden;
}
.productpage__productimages__preview__image {
  display: block;
  transition: opacity 400ms;
  flex: none;
  width: 100%;
  z-index: 0;
}
.productpage__productimages__preview__image[hidden] {
  opacity: 0;
  z-index: -1;
}
.productpage__productimages__preview__image + .productpage__productimages__preview__image {
  margin-left: -100%;
}
.productpage__productimages--multiple .productpage__productimages__preview {
  border-radius: 8px 8px 0 0;
}
.productpage__productimages__thumbs {
  border-radius: 0 0 8px 8px;
  height: 124px;
  padding: 12px 12px 0;
  width: 100%;
  background-color: var(--bs-light);
}
.productpage__productimages__thumbs__scrollarea {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: max-content;
  padding-bottom: 12px;
  align-items: flex-start;
}
.productpage__productimages__thumbs__image {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  position: relative;
}
.productpage__productimages__thumbs__image::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-light);
  opacity: 0.5;
  transition: opacity 300ms;
}
.productpage__productimages__thumbs__image--active::after, .productpage__productimages__thumbs__image:hover::after {
  opacity: 0;
}
.productpage__productimages__thumbs__image + .productpage__productimages__thumbs__image {
  margin-left: 8px;
}
.productpage__orderpanel {
  color: #000;
  border-radius: 8px;
  background-color: var(--bs-light);
  padding: clamp(20px, 4.8vw, 48px);
  flex: none;
  width: 40%;
}
@media (max-width: 750px) {
  .productpage__orderpanel {
    width: 100%;
  }
}
.productpage__orderpanel h1 {
  color: var(--bs-secondary);
  font-size: clamp(24px, 3.2vw, 35px);
  margin-bottom: 15px;
}
.productpage__orderpanel__shortdescription {
  margin-bottom: 15px;
}
.productpage__orderpanel .webshop-product__optionlabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.productpage__orderpanel .webshop-product__options {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0 15px;
  margin-bottom: 15px;
}
.productpage__orderpanel .webshop-product__priceblock {
  font: 600 26px/1.2 var(--font-heading);
}
.productpage__orderpanel .webshop-product__priceblock s {
  opacity: 0.3;
  display: inline-block;
  margin-right: 10px;
}
.productpage__orderpanel__price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.productpage__orderpanel__instocktext {
  color: var(--bs-danger);
  background-color: #fff;
  border-radius: 4px;
  padding: 3px 10px;
}
.productpage__orderpanel__instocktext::before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  padding-right: 10px;
}
.productpage__orderpanel__instocktext--instock {
  color: var(--bs-success);
}
.productpage__orderpanel__instocktext--instock::before {
  content: "\f00c";
}
.productpage__orderpanel__deliverytime {
  margin-top: 10px;
}
.productpage-grid {
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [fullwidth-start] calc(50% - 688px) [contentpadding-left] 12px [content-start] 55fr [content-end] 5fr [right-start] 40fr [right-end] 12px [contentpadding-right] calc(50% - 688px) [fullwidth-end];
}
.productpage-grid > * {
  grid-area: auto/content-start/auto/content-end;
}
.productpage-grid > *.fullwidth {
  grid-area: auto/fullwidth-start/auto/fullwidth-end;
}
.productpage-grid > *.productpage__contents__right {
  grid-area: 1/right-start/1000/right-end;
}
.productpage-grid > *.fullwidth ~ *:not(.fullwidth):not(.productpage__contents__right) {
  grid-area: auto/content-start/auto/right-end;
}
@media (max-width: 990px) {
  .productpage-grid > * {
    grid-area: auto/content-start/auto/right-end;
  }
  .productpage-grid > *.fullwidth {
    grid-area: auto/fullwidth-start/auto/fullwidth-end;
  }
  .productpage-grid > *.productpage__contents__right {
    grid-area: auto/content-start/auto/right-end;
  }
}
.productpage__brand {
  margin-bottom: 20px;
}
.productpage__brand a {
  text-decoration: none;
  display: inline-block;
}
.productpage__brand img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
.productpage__specifications {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  margin: 10px 0 30px;
}
.productpage__specifications dt {
  font-weight: 700;
  flex: none;
  width: 150px;
  margin: 0;
  padding: 8px 0;
}
.productpage__specifications dd {
  margin: 0;
  padding: 8px 0 8px 10px;
  flex: none;
  width: calc(100% - 150px);
}
.productpage__specifications dt, .productpage__specifications dd {
  border-top: 1px solid #ccc;
}
.productpage__specifications dt:nth-child(4n+3),
.productpage__specifications dd:nth-child(4n+4) {
  background-color: var(--bs-light);
}
.productpage__relatedproducts {
  background-color: var(--bs-light);
  position: relative;
  z-index: 0;
}
.productpage__relatedproducts::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: calc(50% - 80px);
  height: 100%;
  z-index: -1;
  background-color: var(--bs-secondary);
}
@media (min-width: 1376px) {
  .productpage__relatedproducts::before {
    width: calc(50% - 100px);
  }
}
.productpage__relatedproducts .contentcontainer__inner {
  display: flex;
  justify-content: space-between;
}
.productpage__relatedproducts .widget-products {
  flex: none;
  width: 55%;
  padding: 30px 0;
}
.productpage__relatedproducts__contact {
  display: none;
  flex: none;
  padding: 30px 0;
  width: 40%;
  color: #fff;
  background-color: var(--bs-secondary);
}
.productpage__relatedproducts__contact h2 {
  color: inherit;
}
.productpage__relatedproducts__contact__block {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 165%;
}
.productpage__relatedproducts__contact__block__icon {
  flex: none;
  background-color: var(--bs-secondary-dark);
  padding: clamp(10px, 1.5vw, 15px);
  font-size: clamp(30px, 4vw, 40px);
  border-radius: 10px;
  margin-right: 15px;
}
.productpage__relatedproducts__contact__block__text {
  flex: 1 1 auto;
}
.productpage__relatedproducts__contact__block__text a {
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  text-underline-position: under;
}
.productpage__relatedproducts__contact__block__text a:hover {
  text-decoration: underline;
}
.widget-products + .productpage__relatedproducts__contact {
  display: block;
}
.productpage__relatedproducts .widget-products__product {
  width: calc(50% - 24px);
}
@media (max-width: 950px) {
  .productpage__relatedproducts .widget-products__product {
    width: calc(33.333% - 24px);
  }
}
@media (max-width: 750px) {
  .productpage__relatedproducts .widget-products__product {
    width: calc(50% - 24px);
  }
}
@media (max-width: 500px) {
  .productpage__relatedproducts .widget-products__product {
    width: calc(100% - 24px);
  }
}
@media (max-width: 950px) {
  .productpage__relatedproducts::before {
    display: none;
  }
  .productpage__relatedproducts .contentcontainer__inner {
    display: block;
  }
  .productpage__relatedproducts .widget-products, .productpage__relatedproducts__contact {
    width: auto;
  }
  .productpage__relatedproducts__contact {
    margin-left: -12px;
    margin-right: -12px;
    padding: 30px 12px;
  }
}
.productpage__tags {
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: -10px;
}
.productpage__tags__link {
  margin-left: 10px;
  background-color: var(--bs-light);
  color: var(--bs-dark);
  text-decoration: none;
  display: inline-block;
  transition: color 300ms;
  padding: 3px 7px;
  margin-bottom: 7px;
}
.productpage__tags__link:hover {
  color: #000;
}