/* use smaller paddings <= 750 */
.widget-innerlinks {
  margin-bottom: 50px;
}
.widget-innerlinks__title {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  color: var(--bs-secondary);
  font-size: 16px;
  width: 100%;
  padding: 8px 45px 8px 15px;
  position: relative;
  text-align: left;
  border: 1px solid #ccc;
  text-decoration: underline;
  text-underline-position: under;
}
.widget-innerlinks__title::after {
  position: absolute;
  display: block !important;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: var(--bs-primary);
  content: "\f067";
}
.wh-details--open > .widget-innerlinks__title::after {
  content: "\f068";
}
.widget-innerlinks__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-innerlinks__links li {
  border: 1px solid #ccc;
  border-top: 0 none;
}
.widget-innerlinks__links a {
  display: block;
  padding: 8px 15px;
  color: var(--bs-primary);
  text-underline-position: under;
  transition: color 300ms;
}
.widget-innerlinks__links a:hover {
  color: var(--bs-primary-dark);
}