.widget-twocol {
  margin-bottom: 30px;
}
.widget-twocol__columns {
  display: flex;
  justify-content: space-between;
}
.widget-twocol__columns__col {
  flex: none;
  width: calc(50% - 24px);
}
.widget-twocol__columns__col > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .widget-twocol__columns {
    display: block;
  }
  .widget-twocol__columns__col {
    width: 100%;
  }
  .widget-twocol__columns__col + .widget-twocol__columns__col {
    margin-top: 30px;
  }
}