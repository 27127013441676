.widget-image {
  margin-bottom: 30px;
}
.widget-image__image {
  width: 100%;
}
.widget-image__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 8px;
}
.widget-image__caption {
  margin-top: 15px;
  font-style: italic;
}