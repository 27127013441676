/* use smaller paddings <= 750 */
.reviewpage__reviewsummary {
  margin-bottom: 30px;
}
.reviewpage__reviewsummary .reviewscore {
  margin: 10px 0;
}
@media (max-width: 450px) {
  .reviewpage__reviewsummary .reviewscore {
    flex-wrap: wrap;
  }
  .reviewpage__reviewsummary .reviewscore__num {
    flex: none;
    width: 100%;
    margin-top: 3px;
    margin-left: 0;
  }
}
.reviewpage__buckets {
  margin-bottom: 30px;
}
.reviewpage__buckets__line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.reviewpage__reviewitem {
  background-color: #fff;
  padding: 24px;
}
.reviewpage__reviewitem:nth-child(2n) {
  background-color: var(--bs-light);
}
.reviewpage__reviewitem__date {
  opacity: 0.6;
}
.reviewpage__reviewitem__text {
  margin: 10px 0;
}
.reviewpage__reviewitem__score {
  margin-top: 15px;
}

.webshop-pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 30px 0;
  display: flex;
  justify-content: center;
}
.webshop-pagination a {
  text-decoration: none;
  transition: color 300ms, background-color 300ms;
}
.webshop-pagination li {
  list-style: none;
  display: inline-block;
  border: 1px solid #ccc;
  text-align: center;
}
.webshop-pagination li > span, .webshop-pagination li > a {
  padding: 5px 10px;
  display: inline-block;
}
.webshop-pagination li + li {
  border-left: none;
}
.webshop-pagination .webshop-pagenav--current {
  background: var(--bs-secondary);
  color: #fff;
}
.webshop-pagination li.webshop-pagenav:not(.webshop-pagenav--current) a:hover {
  background: var(--bs-secondary);
  color: #fff;
}
.webshop-pagination li.webshop-pagenav:first-child:not(.webshop-pagenav--first) .webshop-pagebuton__pagenr {
  display: none;
}
.webshop-pagination li.webshop-pagenav:first-child:not(.webshop-pagenav--first):before {
  content: "...";
}
.webshop-pagination li.webshop-pagenav:last-child:not(.webshop-pagenav--last) .webshop-pagebuton__pagenr {
  display: none;
}
.webshop-pagination li.webshop-pagenav:last-child:not(.webshop-pagenav--last):before {
  content: "...";
}