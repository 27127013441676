@charset "UTF-8";
/* use smaller paddings <= 750 */
.wh-widgetpreview body > .widget {
  margin: 0 auto;
  width: 100%;
  max-width: 320px;
}

.wh-widgetpreview wh-outputtools {
  display: none !important;
}

hr {
  margin-bottom: 20px;
  border-top: 1px solid var(--bs-secondary);
}

.icon-twitter, .fab.fa-twitter {
  display: inline-block;
}
.icon-twitter::before, .fab.fa-twitter::before {
  content: "𝕏" !important;
  font-size: 100%;
  font-weight: 700;
  font-family: Arial-black;
}

.contentcontainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1376px;
  width: 100%;
}
.contentcontainer__inner {
  padding-left: 12px;
  padding-right: 12px;
}

.styledscrollbar-x,
.styledscrollbar-y {
  overflow: hidden;
  scroll-behavior: smooth;
  /* firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--bs-secondary) #fff;
  /* webkit */
}
.styledscrollbar-x::-webkit-scrollbar,
.styledscrollbar-y::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-track-piece,
.styledscrollbar-y::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-button, .styledscrollbar-x::-webkit-scrollbar-corner, .styledscrollbar-x::-webkit-resizer,
.styledscrollbar-y::-webkit-scrollbar-button,
.styledscrollbar-y::-webkit-scrollbar-corner,
.styledscrollbar-y::-webkit-resizer {
  display: none;
}
.styledscrollbar-x::-webkit-scrollbar-thumb,
.styledscrollbar-y::-webkit-scrollbar-thumb {
  border: 2px solid #f1f1f1;
  box-shadow: inset 0 0 7px 7px var(--bs-primary);
}
.styledscrollbar-x::-webkit-scrollbar-track,
.styledscrollbar-y::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px 7px #f1f1f1;
}

.styledscrollbar-x {
  overflow-x: auto;
}

.styledscrollbar-y {
  overflow-y: auto;
}

/* prevent occacional double click from selecting text */
.preventselection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

html {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: auto;
  position: relative;
}

main {
  flex: 1 1 auto;
  position: relative;
}
main > *:first-child:not(.contentpanel):not(.webshop-checkout) {
  margin-top: clamp(30px, 15vw, 100px);
}
main > *:not(.fullwidth):not(.wh-anchor), main > .wh-video {
  margin-left: auto;
  margin-right: auto;
  max-width: 1352px;
  width: calc(100% - 24px);
}

.pageheader--wideimage + main > div:first-child {
  margin-top: 0 !important;
}

header, footer, .mainnav {
  flex: none;
}

.sharepage {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  white-space: nowrap;
}
.sharepage__buttons {
  display: flex;
  align-items: center;
}
.sharepage__label {
  font-family: var(--font-heading);
  font-weight: 600;
  margin-right: 10px;
  font-size: clamp(14px, 1.6vw, 16px);
}
.sharepage__button {
  flex: none;
  margin-left: 5px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: flex;
  font-size: 18px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--bs-secondary-dark);
  transition: background-color 300ms;
}
.sharepage__button:hover {
  background-color: var(--bs-primary);
}

.pageheader {
  background-color: var(--bs-secondary);
  min-height: calc(10vw + 150px);
  position: relative;
  z-index: 0;
  color: #fff;
}
.pageheader::before {
  content: "";
  display: block;
  position: absolute;
  height: calc(10vw + 150px);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-top: 10vw solid transparent;
  border-right: 100vw solid #FFF;
}
.pageheader--productpage .pageheader__inner {
  align-items: flex-start;
}
.pageheader__wideimage > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.pageheader--onlytitle, .pageheader--image {
  min-height: 10vw;
  padding-bottom: 50px;
}
.pageheader--onlytitle::before, .pageheader--image::before {
  height: 10vw;
}
.pageheader p a {
  color: inherit;
}
.pageheader p a:hover {
  color: inherit;
  text-shadow: 0 0 1px #fff;
}
.pageheader__inner {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}
.pageheader__text, .pageheader__cta {
  flex: 1 1 auto;
  max-width: calc(66.666% - 12px);
}
.pageheader__text {
  margin-bottom: 3.5vw;
}
.pageheader__text--wide {
  max-width: 100%;
}
.pageheader__text__divider {
  height: 1px;
  margin: 20px 0;
  background-color: var(--bs-secondary-dark);
  opacity: 0.7;
}
.pageheader__text > *:last-child {
  margin-bottom: 0;
}
.pageheader__image {
  flex: none;
  width: 33.333%;
}
.pageheader__image img {
  max-width: 100%;
  height: auto;
}
.pageheader__image:first-child {
  width: calc(50% - 12px);
}
.pageheader__image + * {
  max-width: calc(50% - 12px);
}
.pageheader .widget-cta__panel {
  background-color: var(--bs-secondary-dark);
  padding: clamp(20px, 5vw, 75px);
  padding-left: clamp(20px, 10vw, 150px);
}
.pageheader .widget-cta__footer {
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: clamp(20px, 10vw, 150px);
}
@media (max-width: 500px) {
  .pageheader .widget-cta__footer {
    flex-flow: column;
  }
}
.pageheader .reviewscore + .widget-cta__usps {
  margin-left: 15px;
}
.pageheader .widget-cta__usps {
  display: flex;
  color: #000;
  overflow: hidden;
}
.pageheader .widget-cta__usps__item {
  flex: none;
  width: 100%;
  opacity: 0;
  transition: opacity 1000ms;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageheader .widget-cta__usps__item--active {
  opacity: 1;
}
.pageheader .widget-cta__usps__item__icon {
  color: var(--bs-secondary);
  flex: none;
  width: 30px;
  font-size: 24px;
  margin-right: 10px;
}
@media (max-width: 750px) {
  .pageheader .widget-cta__usps__item {
    color: #fff;
  }
  .pageheader .widget-cta__usps__item__icon {
    color: #fff;
  }
}
.pageheader .widget-cta__usps__item + div {
  margin-left: -100%;
}
@media (max-width: 500px) {
  .pageheader .widget-cta__usps {
    margin-left: 0 !important;
    margin-top: 15px;
  }
  .pageheader .widget-cta__usps__item {
    justify-content: flex-start;
  }
}
.pageheader .widget-cta__image {
  text-align: center;
  vertical-align: bottom;
  margin-top: 15px;
  position: relative;
  z-index: -1;
}
@media (max-width: 750px) {
  .pageheader .widget-cta__image {
    display: none;
  }
}
.pageheader .widget-cta__image img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  height: auto;
}
@media (max-width: 750px) {
  .pageheader {
    padding-bottom: 10vw;
  }
  .pageheader__inner {
    flex-flow: column;
  }
  .pageheader__image, .pageheader__text, .pageheader__cta {
    flex: none;
    width: 100%;
    max-width: 100%;
  }
  .pageheader__image {
    order: 2;
    margin-top: 20px;
    width: 100% !important;
  }
  .pageheader__image::before {
    content: "";
    display: block;
    position: absolute;
    height: calc(50vw + 150px);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-top: 50vw solid transparent;
    border-right: 100vw solid #FFF;
  }
  .pageheader::before {
    height: 0;
  }
}

.reviewscore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 26px;
}
.reviewscore__stars {
  color: var(--bs-primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reviewscore__stars i {
  margin-right: 3px;
}
.reviewscore__score {
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.reviewscore__score + .reviewscore__stars {
  margin-left: 10px;
}
.reviewscore__stars + div {
  margin-left: 5px;
}
.reviewscore__num {
  font-size: 16px;
  opacity: 0.6;
  margin-left: 5px;
}

.contentpanel {
  padding: clamp(20px, 5vw, 50px);
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.contentpanel > *:last-child {
  margin-bottom: 0;
}
.contentpanel--twocol {
  display: flex;
  padding: 0;
  margin-top: 30px;
  background-color: transparent;
}
.contentpanel__col {
  padding: clamp(20px, 5vw, 50px);
  flex: none;
  width: 50%;
  background-color: #fff;
}
.contentpanel__col + .contentpanel__col {
  border-radius: 0 10px 10px 0;
}
.contentpanel__col .wh-form__buttongroup {
  padding-bottom: 0;
}
.contentpanel__col:first-child {
  background-color: var(--bs-secondary);
  border-radius: 10px 0 0 10px;
  color: #fff;
}
.contentpanel__col:first-child h1, .contentpanel__col:first-child h2, .contentpanel__col:first-child h3 {
  color: inherit;
}
.contentpanel__col:first-child a {
  color: #fff;
}
.contentpanel__col:first-child a:hover {
  color: inherit;
  text-shadow: 0 0 1px #fff;
}
.contentpanel__col:first-child .wh-form__error {
  color: var(--bs-yellow);
}
.contentpanel__col > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .contentpanel--twocol {
    display: block;
  }
  .contentpanel__col {
    width: 100%;
  }
  .contentpanel__col + .contentpanel__col {
    border-radius: 0 0 10px 10px;
  }
  .contentpanel__col:first-child {
    border-radius: 10px 10px 0 0;
  }
}

main > .contentpanel:not(.contentpanel--twocol):first-child {
  margin-top: -6vw;
  position: relative;
}

.cookiebar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 0;
  z-index: 100;
  transition: max-height 300ms;
  background-color: var(--bs-light);
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.05);
}
.cookiebar a {
  color: inherit;
}
.cookiebar--show {
  max-height: 100vh;
}
.cookiebar__inner {
  padding: 24px 12px;
  text-align: center;
}
.cookiebar__buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.cookiebar__buttons button + button {
  margin-left: 12px;
}