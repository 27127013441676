/* use smaller paddings <= 750 */
.header {
  border-bottom: 1px solid var(--bs-gray-300);
  background-color: #fff;
}
.header__inner {
  display: flex;
  align-items: center;
  height: clamp(100px, 15vw, 150px);
}
.header__logo {
  display: block;
  width: clamp(120px, 20vw, 205px);
  flex: none;
}
.header__logo img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.header__search {
  flex: 1 1 auto;
  padding: 0 10px;
}
.header__search ::-webkit-input-placeholder {
  opacity: 1;
  font-style: italic;
}
.header__search ::-moz-placeholder {
  opacity: 1;
  font-style: italic;
}
.header__search :-ms-input-placeholder {
  opacity: 1;
  font-style: italic;
}
.header__search input:-moz-placeholder {
  opacity: 1;
  font-style: italic;
}
.header__search__form {
  display: flex;
  width: 100%;
  max-width: 325px;
  margin: 0 auto;
  border: 1px solid #ddd;
  height: 40px;
  border-radius: 20px;
  padding: 0 15px 0 12px;
  align-items: center;
}
.header__search__form__button {
  flex: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 20px;
  color: #999;
  margin-right: 12px;
}
.header__search__form__input {
  border: 0 none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  min-width: 100px;
  flex: 1 1 auto;
}
.header__shopactions {
  flex: none;
  display: flex;
  align-items: center;
}
.header__shopactions a {
  text-decoration: none;
  display: flex;
  font-size: 14px;
  color: var(--bs-secondary);
  transition: color 300ms;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-left: 10px;
}
.header__shopactions a:hover {
  color: var(--bs-primary);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.header__shopactions a i {
  font-size: 24px;
  padding: 0 5px;
  margin-bottom: 5px;
}
@media (max-width: 450px) {
  .header__shopactions a span {
    display: none;
  }
}
.header__shopactions__cart {
  position: relative;
}
.header__shopactions__cart__count {
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(5px);
  top: -10px;
  background-color: var(--bs-secondary);
  line-height: 20px;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  padding: 0 5px;
  min-width: 20px;
}

.header-mobile {
  display: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid var(--bs-gray-300);
}
html.viewmode--mobile .header-mobile {
  display: flex;
}
.header-mobile__showmenu {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  align-items: center;
  font-size: 18px;
  padding: 12px;
  line-height: 26px;
}
.header-mobile__showmenu span {
  display: inline-block;
  margin-right: 12px;
}
@media (max-width: 400px) {
  .header-mobile__showmenu span {
    display: none;
  }
}
.header-mobile__showmenu i {
  color: var(--bs-secondary);
}
.header-mobile__search {
  flex: 1 1 auto;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}