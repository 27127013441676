/* use smaller paddings <= 750 */
.widget-linkswiththumbs {
  margin-bottom: 50px;
}
.widget-linkswiththumbs__container {
  padding: 20px 0;
  border-bottom: 1px solid #D8D8D8;
  border-top: 1px solid #D8D8D8;
  position: relative;
}
.widget-linkswiththumbs__scrollwrapper {
  transform: translateZ(0);
  overflow: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.widget-linkswiththumbs__scrollwrapper::-webkit-scrollbar {
  display: none;
}
.widget-linkswiththumbs__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
}
.widget-linkswiththumbs__list li + li {
  margin-left: 15px;
}
.widget-linkswiththumbs button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px 10px;
  font-size: clamp(16px, 20px, 2dvw);
  opacity: 0.5;
  transition: opacity 0.3s;
}
.widget-linkswiththumbs button:hover {
  opacity: 1;
}
.widget-linkswiththumbs__scrollleft {
  right: 100%;
}
.widget-linkswiththumbs__scrollright {
  left: 100%;
}
.widget-linkswiththumbs__scrollwrapper.showbuttons ~ button {
  display: block;
}
.widget-linkswiththumbs__scrollwrapper.scrollx-at-start ~ .widget-linkswiththumbs__scrollleft, .widget-linkswiththumbs__scrollwrapper.scrollx-at-end ~ .widget-linkswiththumbs__scrollright {
  opacity: 0.1;
  pointer-events: none;
}
.widget-linkswiththumbs a {
  display: flex;
  text-decoration: none;
  text-underline-position: under;
  color: inherit;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  white-space: nowrap;
}
.widget-linkswiththumbs a:hover > span {
  text-decoration: underline;
}
.widget-linkswiththumbs a img {
  width: 38px;
  height: auto;
  margin-right: 10px;
}