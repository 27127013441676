/*

contentwidth 1400 (1376px + 2*12px)

Font:
Kop: Exo,sans-serif
text Nunito,sans-serif

Kleuren:
groenblauw #6b9eab;
goud       #d3a73e;

text kleur muted #6c757d;
footer achtergrond: #f0f5f9;

*/
:root, .wh-rtd-editor
{
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #d3a73e;
  --bs-primary-dark: #a98636;
  --bs-secondary: #6b9eab;
  --bs-secondary-dark: #4e7278;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f0f5f9;
  --bs-dark: #3c3c3b;

  --font-heading: Exo, Sans-serif;
  --font-default: Nunito, Sans-Serif;

  --color-grey-placeholder: #737373;
}

:root
{
  --content-width-xwide: 1400px;
  --content-padding: 12px;
}
