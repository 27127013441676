/* use smaller paddings <= 750 */
.mainnav {
  background-color: #fff;
  border-bottom: 1px solid var(--bs-gray-300);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  position: relative;
  z-index: 100;
}
.mainnav__inner {
  position: relative;
  height: 60px;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 clamp(0px, 1.2vw, 12px);
  margin: 0;
}
.mainnav__item {
  padding: 0 clamp(0px, 1vw, 16px);
}
.mainnav__item__link {
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 60px;
  color: inherit;
  text-decoration: none;
}
.mainnav__item__link__icon {
  flex: none;
  width: 24px;
  margin-right: 5px;
  opacity: 0.45;
}
.mainnav__item__link__icon img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.mainnav__item--withsubitems .mainnav__item__link::after {
  display: inline-block;
  margin-left: 10px;
  content: "";
  border-top: 6px solid #000;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.mainnav__item__panel {
  position: absolute;
  left: var(--content-padding);
  right: var(--content-padding);
  top: 100%;
  margin-top: 1px;
  background-color: #fff;
  transition: opacity 300ms;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.mainnav__item__panel[hidden] {
  display: block;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
html:not(.viewmode--mobile) .mainnav__item__panel {
  min-height: 60px !important;
}
html.viewmode--mobile .mainnav__item__panel {
  left: 0;
  top: 0;
  max-height: calc(100% - 60px);
  right: auto;
  width: 100%;
  box-shadow: none;
  transition: left 300ms;
}
html.viewmode--mobile .mainnav__item__panel[hidden] {
  z-index: 1;
  visibility: visible;
  left: 100%;
  opacity: 1;
}
.mainnav__item__panel__backbutton {
  display: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 18px;
  width: 100%;
  height: 60px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px 10px 12px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}
.mainnav__item__panel__backbutton__title {
  flex: 1 1 auto;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainnav__item__panel__backbutton__icon {
  flex: none;
}
html.viewmode--mobile .mainnav__item__panel__backbutton {
  display: flex;
}
.mainnav__item__panel__inner {
  display: flex;
}
.mainnav__item__panel__optiontypes {
  flex: none;
  width: 25%;
  min-width: 320px;
  background-color: var(--bs-light);
}
.mainnav__item__panel__optionbutton {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: relative;
  font-size: 18px;
  width: 100%;
  height: 60px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px 10px 40px;
  transition: color 300ms, background-color 300ms;
}
.mainnav__item__panel__optionbutton::after {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  border-top: 30px solid transparent;
  border-left: 25px solid transparent;
  border-bottom: 30px solid transparent;
  transition: border-color 300ms;
}
.mainnav__item__panel__optionbutton:hover, .mainnav__item__panel__optionbutton--selected {
  background-color: var(--bs-secondary);
  color: #fff;
}
.mainnav__item__panel__optionbutton:hover::after, .mainnav__item__panel__optionbutton--selected::after {
  border-left-color: var(--bs-secondary);
}
.mainnav__item__panel__optionbutton__icon {
  flex: none;
  width: 30px;
  margin-right: 15px;
}
.mainnav__item__panel__optionbutton__icon img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
html.viewmode--mobile .mainnav__item__panel__optionbutton {
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 10px 15px 10px 12px;
  color: #000;
}
html.viewmode--mobile .mainnav__item__panel__optionbutton:last-child {
  border-bottom: 1px solid #ccc;
}
html.viewmode--mobile .mainnav__item__panel__optionbutton:hover {
  background-color: var(--bs-secondary);
  color: #fff;
}
html.viewmode--mobile .mainnav__item__panel__optionbutton__icon {
  display: none;
}
.mainnav__item__panel__subpanels {
  flex: 1 1 auto;
  padding: 40px;
}
.mainnav__item__panel__subpanels__panel {
  display: none;
  opacity: 0;
  transition: opacity 300ms;
}
.mainnav__item__panel__subpanels__panel--selected {
  display: block;
  opacity: 1;
}
.mainnav__item__panel__subpanels__panel__inner {
  display: flex;
  align-items: flex-start;
}
html.viewmode--mobile .mainnav__item__panel__subpanels__panel {
  background-color: #fff;
  width: 320px;
  display: block;
  position: absolute;
  transition: left 300ms;
  left: 100%;
  top: 0;
  opacity: 1;
}
html.viewmode--mobile .mainnav__item__panel__subpanels__panel--selected {
  left: 0;
}
html.viewmode--mobile .mainnav__item__panel__subpanels__panel__inner {
  flex-flow: column;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100svh - 120px);
}
.mainnav__item__panel__cta {
  flex: none;
  width: 20%;
}
html.viewmode--mobile .mainnav__item__panel__cta {
  display: none;
}
.mainnav__item:focus .mainnav__item__panel[hidden], .mainnav__item:focus-within {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.mainnav__productoptions {
  font-size: 18px;
  display: block;
  flex: none;
  width: calc(50% - 24px);
}
.mainnav__productoptions + .mainnav__productoptions {
  margin-left: 24px;
}
.mainnav__productoptions__header {
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 10px;
}
.mainnav__productoptions__header__icon {
  flex: none;
  width: 30px;
  margin-right: 15px;
}
.mainnav__productoptions__header__icon img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.mainnav__productoptions__header__title {
  font-weight: 800;
}
.mainnav__productoptions ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #F9F9F9;
  border-radius: 18px;
  padding: 24px;
}
.mainnav__productoptions ul li + li {
  margin-top: 15px;
}
.mainnav__productoptions ul a {
  text-underline-position: under;
  color: var(--bs-primary);
}
html.viewmode--mobile .mainnav__productoptions {
  width: 100%;
  padding: 0;
}
html.viewmode--mobile .mainnav__productoptions + .mainnav__productoptions {
  margin-left: 0;
  margin-top: 24px;
}
html.viewmode--mobile .mainnav__productoptions__header {
  padding: 12px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
html.viewmode--mobile .mainnav__productoptions ul {
  border-radius: 0;
  background-color: transparent;
  padding: 0;
}
html.viewmode--mobile .mainnav__productoptions ul li + li {
  margin-top: 0;
}
html.viewmode--mobile .mainnav__productoptions ul a {
  text-decoration: none;
  display: block;
  padding: 12px;
  border-bottom: 1px solid #ccc;
}
html.viewmode--mobile .mainnav__productoptions ul a:hover {
  text-decoration: underline;
}
.mainnav__hidemenu {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  text-align: left;
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  padding: 12px;
  line-height: 36px;
  width: 100%;
  color: var(--bs-primary);
  flex: none;
}
.mainnav__hidemenu span {
  display: inline-block;
  margin-right: 12px;
  flex: 1 1 auto;
}
.mainnav__hidemenu i {
  font-size: 24px;
}
.mainnav__item__showpanelbutton {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  color: var(--bs-primary);
  width: 60px;
  height: 100%;
  font-size: 18px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-left: 1px solid #ccc;
}
html.viewmode--mobile .mainnav {
  overflow: hidden;
  max-width: 0;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: max-width 300ms;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  z-index: 10;
}
html.viewmode--mobile .mainnav::before {
  content: "";
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #000;
  transition: opacity 300ms;
}
html.viewmode--mobile .mainnav .contentcontainer {
  background-color: var(--bs-light);
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
html.viewmode--mobile .mainnav__inner {
  flex: none;
  flex-flow: column;
  height: auto;
  padding: 0;
  justify-content: flex-start;
}
html.viewmode--mobile .mainnav__hidemenu {
  display: flex;
}
html.viewmode--mobile .mainnav__item {
  border-bottom: 1px solid #ccc;
  padding: 0;
  background-color: #fff;
  height: 60px;
}
html.viewmode--mobile .mainnav__item__link {
  color: var(--bs-primary);
  padding: 0 0 0 12px;
  flex: 1 1 auto;
}
html.viewmode--mobile .mainnav__item__link__icon {
  display: none;
}
html.viewmode--mobile .mainnav__item__link__title {
  flex: 1 1 auto;
}
html.viewmode--mobile .mainnav__item--withsubitems .mainnav__item__link::after {
  display: none;
}
html.viewmode--mobile .mainnav__item--withsubitems {
  display: flex;
}
html.viewmode--mobile .mainnav__item__showpanelbutton {
  display: inline-flex;
}
html.viewmode--mobile.showmenu .mainnav {
  max-width: 320px;
}
html.viewmode--mobile.showmenu .mainnav::before {
  width: 100%;
  opacity: 0.4;
}

html.viewmode--mobile.showmenu body {
  overflow: hidden;
}