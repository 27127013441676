/* use smaller paddings <= 750 */
.widget-widgetgroup {
  margin-bottom: 30px;
}
.widget-widgetgroup__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -24px;
}
.widget-widgetgroup__list__item {
  flex: none;
  margin-left: 24px;
  width: calc(33.3333% - 24px);
  margin-bottom: 24px;
}
.widget-widgetgroup__list__item > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 900px) {
  .widget-widgetgroup__list__item {
    width: calc(50% - 24px);
  }
}
@media (max-width: 600px) {
  .widget-widgetgroup__list {
    display: block;
  }
  .widget-widgetgroup__list__item {
    width: auto;
  }
}