/* use smaller paddings <= 750 */
.webshop-checkout {
  margin-top: 30px;
}

.webshop-listcart a {
  color: var(--bs-primary);
  text-decoration: none;
  transition: color 300ms;
}
.webshop-listcart a:hover {
  color: var(--bs-primary-dark);
  text-decoration: underline;
  text-underline-position: under;
}
.webshop-listcart__productstable {
  width: calc(100% + 24px);
  border-collapse: collapse;
  border: 0 none;
  margin: 0 -12px 25px;
}
.webshop-listcart__productrow:nth-child(2n) {
  background-color: var(--bs-light);
}
.webshop-listcart__productrow > * {
  padding-top: 5px;
  padding-bottom: 5px;
}
.webshop-listcart__productimage {
  padding-left: 12px;
}
.webshop-listcart__imagecell, .webshop-listcart__productimage {
  width: clamp(50px, 8vw, 100px);
}
.webshop-listcart__titlecell {
  padding: 5px 12px;
}
.webshop-listcart__productamountcell {
  width: clamp(80px, 10vw, 100px);
}
.webshop-listcart__productamountcell input, .webshop-listcart__productamountcell .wh-form__pulldown-styled {
  width: clamp(80px, 10vw, 100px);
}
.webshop-listcart__deletecell {
  padding: 5px 12px;
}
.webshop-listcart__linetotalcell {
  text-align: right;
  padding-right: 12px;
}
.webshop-listcart__totalcell {
  text-align: right;
  background-color: #f8f8f8;
  padding: 12px;
}
.webshop-listcart__totalcell .webshop-listcart__totallabel {
  display: inline-block;
  margin-right: 10px;
}
.webshop-listcart__productimage {
  display: block;
}
.webshop-listcart__productimage img {
  width: 100%;
  height: auto;
}
.webshop-listcart__delete {
  cursor: pointer;
  padding: 5px;
}
.webshop-listcart__delete::before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  display: inline-block;
  content: "\f2ed";
}
.webshop-listcart__options {
  margin-top: 5px;
  font-style: italic;
  font-size: 14px;
}
.webshop-listcart__option {
  display: flex;
  align-items: baseline;
}
.webshop-listcart__optionlabel {
  display: inline-block;
  padding-right: 7px;
}
@media (max-width: 600px) {
  .webshop-listcart__productstable {
    margin-right: 0;
  }
  .webshop-listcart__productrow {
    display: flex;
    flex-wrap: wrap;
  }
  .webshop-listcart__productrow > * {
    display: inline-flex;
    align-items: center;
  }
  .webshop-listcart__titlecell {
    width: calc(100% - 190px);
    flex-wrap: wrap;
  }
  .webshop-listcart__deletecell {
    flex: none;
    width: 50px;
  }
  .webshop-listcart__productamountcell {
    padding-left: 72px;
    width: 152px !important;
    padding-bottom: 10px;
  }
  .webshop-listcart__linetotalcell {
    width: calc(100% - 152px);
    padding-top: 0;
    padding-bottom: 10px;
    justify-content: flex-end;
  }
  .webshop-listcart__options {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .webshop-listcart__titlecell {
    width: calc(100% - 60px);
    flex-wrap: wrap;
  }
  .webshop-listcart__productamountcell {
    padding-left: 72px;
    width: 152px;
    padding-bottom: 10px;
  }
  .webshop-listcart__linetotalcellx {
    width: calc(100% - 80px);
    padding-left: 12px;
    padding-top: 5px;
  }
  .webshop-listcart__options {
    width: 100%;
  }
}

.webshop-checkout__ordertotals {
  text-align: right;
}
.webshop-checkout__ordertotals h4 {
  color: #000;
}

.skw-orderstatusblock {
  display: flex;
  align-items: flex-start;
}
.skw-orderstatusblock__image {
  width: 32px;
  flex: none;
  margin-right: 10px;
  text-align: center;
}
.skw-orderstatusblock__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.webshop-account__fullbillingaddress,
.webshop-account__fullorderaddress {
  margin-bottom: 24px;
}

.webshop-account__ordercontents {
  margin-bottom: 24px;
}
.webshop-account__ordercontents table {
  border-collapse: collapse;
  width: 100%;
}
.webshop-account__ordercontents th {
  text-align: left;
  white-space: nowrap;
  padding-bottom: 3px;
}
.webshop-account__ordercontents td {
  text-align: right;
  vertical-align: middle;
  padding-bottom: 3px;
}
.webshop-account__ordercontents th + th, .webshop-account__ordercontents td + td {
  padding-left: 10px;
}
.webshop-account__ordercontents .webshop-account__productlineimage img {
  width: 60px;
  height: auto;
  vertical-align: bottom;
}
.webshop-account__ordercontents .webshop-account__productlineamount {
  text-align: center;
}
.webshop-account__ordercontents .webshop-account__productlinetitle {
  text-align: left;
  width: 100%;
}
.webshop-account__ordercontents .webshop-account__total, .webshop-account__ordercontents .webshop-account__titletotal {
  border-top: 1px solid var(--bs-dark);
  padding-top: 5px;
  font-weight: 600;
}

.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.zip"] {
  width: calc(100% - 150px);
  float: left;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.zip"] + .wh-form__fieldgroup[data-wh-form-group-for$="address.nr_detail"] {
  width: 130px;
  float: right;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.street"] {
  width: calc(100% - 200px);
  float: left;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.street"] + .wh-form__fieldgroup[data-wh-form-group-for$="address.city"] {
  width: 180px;
  float: right;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.zip"] + .wh-form__fieldgroup:not([data-wh-form-group-for$="address.nr_detail"]),
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.street"] + .wh-form__fieldgroup:not([data-wh-form-group-for$="address.city"]) {
  clear: both;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.nr_detail"] + .wh-form__fieldgroup,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for$="address.city"] + .wh-form__fieldgroup {
  clear: both;
}
.webshop-checkout__form .skw-checkout__userdata::after {
  display: block;
  clear: both;
  content: "";
}

.checkoutpage__panel {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
  border-radius: 12px;
  background-color: #fff;
  padding: clamp(25px, 3.5vw, 35px);
}
@media (min-width: 400px) {
  .checkoutpage__nameparts {
    display: flex;
    justify-content: space-between;
  }
  .checkoutpage__nameparts .wh-form__fieldgroup {
    flex: none;
    width: calc(50% - 10px);
  }
}
.checkoutpage__logos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}
.checkoutpage__logos__postnl {
  width: 50px;
  height: auto;
  vertical-align: bottom;
}
.checkoutpage__repeatorderonpage {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
  display: flex;
  align-items: flex-start;
  border-radius: 12px;
  background-color: var(--bs-light);
}
.checkoutpage__repeatorderonpage .skw-checkout__userdata {
  padding: clamp(20px, 3.5vw, 35px);
}
.checkoutpage__repeatorderonpage .webshop-listcart__productamountcell {
  width: clamp(40px, 4vw, 60px);
}
.checkoutpage__repeatorderonpage .webshop-checkout__terms {
  background-color: var(--bs-secondary);
  color: #fff;
  padding: clamp(20px, 3.5vw, 35px);
  border-radius: 0 0 0 12px;
}
.checkoutpage__repeatorderonpage .webshop-checkout__terms h3 {
  color: inherit;
}
.checkoutpage__repeatorderonpage .webshop-checkout__terms .wh-form__buttongroup {
  margin: 0;
  padding: 30px 0 0;
}
.checkoutpage__repeatorderonpage .webshop-checkout__terms .wh-form__buttongroup .button--outline {
  color: #fff;
}
.checkoutpage__repeatorderonpage .skw-checkout__inclusief {
  border-top: 1px solid var(--bs-gray-300);
  padding-top: 20px;
  text-align: right;
  margin-bottom: 0;
}
.checkoutpage__repeatorderonpage .checkoutpage__panel {
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
}
.checkoutpage__col {
  flex: 1 1 50%;
  max-width: 50%;
}
.checkoutpage__repeatorderonpage .checkoutpage__col:first-child .checkoutpage__panel {
  border-radius: 12px 0 0 12px;
}
.checkoutpage__repeatorderonpage .checkoutpage__col + .checkoutpage__col {
  flex: 1;
  width: auto;
  margin: clamp(20px, 4vw, 60px);
  min-width: 400px;
}
.checkoutpage__repeatorderonpage .checkoutpage__col + .checkoutpage__col .checkoutpage__panel {
  padding: clamp(25px, 3.5vw, 35px);
}
@media (max-width: 900px) {
  .checkoutpage__repeatorderonpage {
    flex-flow: column;
    align-items: stretch;
  }
  .checkoutpage__repeatorderonpage .webshop-checkout__terms {
    border-radius: 0 0 12px 12px;
  }
  .checkoutpage__repeatorderonpage .checkoutpage__col:first-child .checkoutpage__panel {
    border-radius: 0 0 12px 12px;
  }
  .checkoutpage__repeatorderonpage .checkoutpage__col {
    flex: none;
    max-width: 100%;
    order: 2;
  }
  .checkoutpage__repeatorderonpage .checkoutpage__col + .checkoutpage__col {
    order: -1;
    min-width: 0;
  }
  .checkoutpage__repeatorderonpage .checkoutpage__col + .checkoutpage__col .checkoutpage__panel {
    border-radius: 12px;
  }
}
.checkoutpage__addreductioncode {
  margin-bottom: 20px;
}
.checkoutpage__addreductioncode__form {
  display: flex;
  justify-content: flex-start;
}
.checkoutpage__addreductioncode__form input {
  flex: 1;
  max-width: 200px;
  min-width: 100px;
}
.checkoutpage__addreductioncode__form button {
  flex: none;
  margin-left: 12px;
}
.checkoutpage__paymentmethods .wh-form__subfield {
  max-width: 250px;
}