/* use smaller paddings <= 750 */
.widget-textpanel {
  margin-bottom: 30px;
  display: flex;
  border-radius: 10px;
  background-color: var(--bs-light);
  padding: clamp(15px, 2.5vw, 25px);
  align-items: flex-start;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.widget-textpanel-wrapper .widget-textpanel {
  height: 100%;
}
.widget-textpanel__icon {
  width: clamp(30px, 5vw, 50px);
  flex: none;
  margin-right: clamp(20px, 2.4vw, 24px);
}
.widget-textpanel__icon img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-textpanel__text {
  flex: 1 1 auto;
  color: #000;
}
.widget-textpanel__text > *:last-child {
  margin-bottom: 0;
}

.pageheader .widget-textpanel {
  background-color: #fff;
}