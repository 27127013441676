
/*
Buttons:
  .button ( default --bs-primary )
  .button--secondary
  .button--white
  .button--outline

  example
  <a href="#" class="button button--secondary">
    <i class="button__icon fal fa-chevron-left"></i>
    <span class="button__label">Sign up</span>
  </a>
*/

.wh-form__buttongroup
{
  text-align: right;
  padding: 30px 0;
  margin-top: 30px;

  &::after
  {
    content: "";
    display: block;
    clear: both;
  }

  .wh-form__button, .button
  {
    margin-bottom: 10px;
  }

  .wh-form__button + .wh-form__button
  , .button + .button
  {
    margin-left: 15px;
  }
}

.button, .wh-form__button
{
  outline: none;
  -webkit-appearance: none;
  position: relative;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-decoration: none;
  font: 16px/150% Nunito, Sans-Serif;
  font-size: clamp(15px, 16px, 1.6dvw);
  min-width: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: clamp(34px, 38px, 3.8dvw);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 0 clamp(10px, 15px, 1.5dvw);
  background-color: var(--bs-primary);
  color: #fff;
  text-align: left;
  transition: opacity 0.3s;
  opacity: 1;
  box-shadow: none;
  margin: 0;
  border: 0 none;
}

.button[disabled], .wh-form__button[disabled]
{
  opacity: 0.6 !important;
  pointer-events: none;
}

.button--large
{
  height: 54px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 15px;
}

.button__icon
{
  display: inline-flex;
  flex: none;
  align-items: center;
  font-size: 11px;
 }

.button__icon:first-child
{
  margin-right: clamp(10px, 15px, 1.5dvw);
  font-size: clamp(16px, 18px, 1.8dvw);
}

.buttonlabel, .button__label
{
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  flex: 1 1 auto;
}

.button__label:last-child
{
  text-align: center;
}

.button__icon + .button__label
{
  text-align: left;
}

  .button__label + .button__icon
, .buttonlabel + .button__icon
{
  margin-left: clamp(10px, 15px, 1.5dvw);
}

.button--secondary
{
  background-color: var(--bs-secondary);
}
.pageheader .button--secondary
{
  background-color: var(--bs-secondary-dark);
}

.button--white
{
  background-color: #fff;
  color: #000;
}

.button--outline
{
  background-color: transparent;
  color: #000;
  border: 1px solid var(--bs-primary);
  transition: border-color 0.2s;
}

.button--secondary.button--outline
{
  border: 1px solid var(--bs-secondary);
}

.button--text
{
  min-width: 0;
  color: var(--bs-primary);
  justify-content: flex-start;
  background-color: transparent;
  align-items: baseline;
  min-height: 27px;
  border-radius: 0;
  white-space: normal;
  height: auto;
  padding: 0;
}
.button--text .button__icon:first-child
{
  margin-right: 10px;
  font-size: 14px;
}
.button--text .button__label
{
  text-decoration: underline;
  text-underline-position: under;
}

.button--text.button--secondary
{
  color: var(--bs-secondary);
}

.button--outline.button--text
{
  border: 0 none;
}

.button--outline.button--text .button__icon
{
  background-color:transparent;
  border: 1px solid var(--color-black);
  color: var(--color-black);
}

  .wh-form__button--previous, .button--previous
, .wh-form__button--cancel, .button--cancel
, .button--floatleft
{
  float: left;
}

.wh-form__button--cancel, .button--cancel
{
  float: none;
  vertical-align: bottom;
  min-width: 80px;
}
