/* use smaller paddings <= 750 */
.widget-categories {
  margin-bottom: 30px;
}
.widget-categories__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
.widget-categories__item {
  padding-left: 24px;
  margin-bottom: 24px;
  flex: none;
  width: 25%;
}
.widget-categories__item__link {
  height: 100%;
  display: flex;
  flex-flow: column;
  text-decoration: none;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow 300ms;
}
.widget-categories__item__link:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.widget-categories__item__image {
  flex: 1 1 auto;
}
.widget-categories__item__image img {
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 10px 10px 0 0;
}
.widget-categories__item__title {
  padding: clamp(15px, 24px, 2.4dvw);
  font-family: var(--font-heading);
  font-size: clamp(15px, 18px, 1.8vw);
  font-weight: 700;
  hyphens: auto;
  color: var(--bs-secondary);
}
@media (max-width: 1023px) {
  .widget-categories__item {
    width: 33.3333%;
  }
}
@media (max-width: 700px) {
  .widget-categories__item {
    width: 50%;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .widget-categories__list {
    margin-left: -15px;
  }
  .widget-categories__item {
    padding-left: 15px;
    margin-bottom: 15px;
  }
}