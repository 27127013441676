/* css styled pulldown */
.wh-form__pulldown-styled {
  position: relative;
  flex: 1;
}
.wh-form__pulldown-styled select {
  padding-right: 35px !important;
  width: 100%;
}
.wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form__pulldown-styled .arrow {
  font-size: 12px;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -5px;
  pointer-events: none;
}
.wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}
.wh-form__pulldown-styled::after {
  display: block;
  content: "";
  width: 15px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 32px;
  top: 2px;
  bottom: 2px;
  pointer-events: none;
  background: linear-gradient(to right, rgba(248, 249, 252, 0) 0%, #f8f9fc 100%);
}
.wh-form__pulldown-styled:focus-within::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
.wh-form__pulldown-styled--disabled::after {
  display: none;
}

.webshop-product__option.wh-styledinput .wh-form__pulldown-styled::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
}
.webshop-product__option.wh-styledinput .wh-form__pulldown-styled select {
  background-color: #fff;
}

.wh-form__fieldgroup--disabled .wh-form__pulldown-styled select[disabled] {
  opacity: 0.6;
}

.wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline .wh-form__pulldown-styled .arrow {
  color: var(--bs-danger);
}