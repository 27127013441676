/* use smaller paddings <= 750 */
.structuredbreadcrumb {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 25px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.structuredbreadcrumb li {
  display: inline-block;
}
.structuredbreadcrumb li + li::before {
  content: "/";
  padding: 0 10px;
  display: inline-block;
}
.structuredbreadcrumb li > span {
  opacity: 0.6;
}
.structuredbreadcrumb a {
  text-decoration: none;
  text-underline-position: under;
  color: inherit;
}
.structuredbreadcrumb a:hover {
  text-decoration: underline;
}

.footer {
  margin-top: clamp(24px, 5vw, 50px);
}
.footer__newsletter {
  position: relative;
  min-height: 100px;
}
.footer__newsletter::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 20px;
  background-color: var(--bs-light);
  z-index: -1;
}
.footer__newsletter__inner {
  display: flex;
}
.footer__newsletter__image {
  flex: none;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.footer__newsletter__image img {
  width: 100%;
  max-width: 220px;
  height: auto;
  vertical-align: bottom;
}
.footer__newsletter__form {
  flex: 1 1 auto;
  background-color: var(--bs-secondary);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.footer__newsletter__form input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  height: 36px;
  border-radius: 4px;
  padding: 0 10px;
  background-color: #fff;
  width: 480px;
  max-width: calc(100% - 40px);
}
.footer__newsletter__form button {
  margin-top: 10px;
}
.footer__newsletter__form p, .footer__newsletter__form h4 {
  color: #fff;
  text-align: center;
}
@media (max-width: 750px) {
  .footer__newsletter {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .footer__newsletter::before {
    top: 0;
    bottom: 0;
  }
  .footer__newsletter__inner {
    display: block;
  }
  .footer__newsletter__image {
    width: 100%;
  }
}
.footer__nav {
  background-color: #fff;
  padding: 60px 0;
}
.footer__nav__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__nav a {
  color: var(--bs-primary);
  text-decoration: none;
}
.footer__nav__col {
  width: calc(25% - 12px);
  flex: none;
}
.footer__nav__col > *:last-child {
  margin-bottom: 0;
}
.footer__nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer__nav ul + h5 {
  margin-top: 50px;
}
.footer__nav ul:last-child {
  margin-bottom: 0;
}
.footer__nav ul li {
  margin-top: 10px;
}
.footer__nav h5 {
  margin-bottom: 20px;
}
.footer__nav__link {
  display: inline-flex;
  align-items: baseline;
}
.footer__nav__link:hover > span {
  text-underline-position: under;
  text-decoration: underline;
}
.footer__nav__link__icon {
  flex: none;
  margin-right: 10px;
}
@media (max-width: 950px) {
  .footer__nav__col {
    width: calc(50% - 12px);
  }
  .footer__nav__col:nth-child(3), .footer__nav__col:nth-child(4) {
    margin-top: 20px;
  }
  .footer__nav h5 {
    margin-bottom: 10px;
  }
  .footer__nav ul + h5 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
@media (max-width: 550px) {
  .footer__nav__inner {
    display: block;
  }
  .footer__nav__col {
    width: 100%;
  }
  .footer__nav__col:nth-child(2) {
    margin-top: 20px;
  }
}
.footer__bottom {
  background-color: var(--bs-light);
  padding: 40px 0;
}
.footer__bottom__inner {
  text-align: center;
}
.footer__bottom__nav {
  margin-bottom: 15px;
}
.footer__bottom a {
  color: var(--bs-primary);
  text-underline-position: under;
}
.footer__bottom__terms {
  max-width: 1024px;
  margin: 0 auto;
  font-size: 14px;
}