@import "/.publisher/sd/slaapkamerweb/dekbedacties/fonts/exo/exo.css";
@import "/.publisher/sd/slaapkamerweb/dekbedacties/fonts/nunito/nunito.css";
@import "@mod-slaapkamerweb/web/fonts/fontawesome5/css/all.min.css";

html
{
  background-color: #fff;
  font: 16px/150% Nunito, Sans-Serif;
  color: #000;
}

h1, h2, h3, h4, h5
{
  font-family: Exo, Sans-serif;
  line-height: 120%;
  font-weight: 600;
}
h1
{
  font-size: clamp(27px, 4vw, 40px);
}
main h1
{
  color: var( --bs-secondary);
  margin-bottom: clamp(15px, 3vw, 30px);
}

h2, h1.smaller
{
  font-size: clamp(24px, 3.2vw, 32px);
  color: var( --bs-secondary);
  margin-bottom: 15px;
}
h3, h2.smaller
{
  font-size: clamp(22px, 2.8vw, 28px);
  color: var( --bs-secondary);
  margin-bottom: 10px;
}
h4, h3.smaller
{
  font-size: clamp(21px, 2.4vw, 24px);
  margin-bottom: 5px;
  color: var( --bs-secondary);
}
h5, h4.smaller
{
  font-size: clamp(18px, 2vw, 20px);
  color: var( --bs-secondary);
}

p a:not(.button), .unordered a:not(.button), .ordered a:not(.button), .checklist a:not(.button), .pluslist a:not(.button)
{
  color: var(--bs-primary);
  text-underline-position: under;
  text-decoration-thickness: 1px;
}

p
{
  margin-bottom: 20px;
  line-height: 160%;
}

p.intro
{
  font-size: clamp(18px, 2vw, 20px);
  line-height: 145%;
}

p a:not(.button), .unordered a:not(.button), .ordered a:not(.button)
, .checklist a:not(.button), .pluslist a:not(.button), .positivepointlist a:not(.button), .negativepointlist a:not(.button)
{
  color: inherit;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  color: var(--bs-primary);
  transition: color 300ms;
}
p a:not(.button):hover, .unordered a:not(.button):hover, .ordered a:not(.button):hover
, .checklist a:not(.button):hover, .pluslist a:not(.button):hover, .positivepointlist a:not(.button):hover, .negativepointlist a:not(.button):hover
{
  color: var(--bs-primary-dark);
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
ul.unordered, ul.checklist, ul.pluslist, ul.positivepointlist, ul.negativepointlist
, ol.ordered
{
  margin: 0 0 30px;
}
  ul.unordered ul, ul.checklist ul, ul.pluslist ul, ul.positivepointlist ul, ul.negativepointlist ul
, ol.ordered ul
, ul.unordered ol, ul.checklist ol, ul.pluslist ol, ul.positivepointlist ol, ul.negativepointlist ol
, ol.ordered ol
{
  margin-bottom: 0;
}

  p + ul.unordered
, p + ul.positivepointlist
, p + ul.negativepointlist
, p + ol.ordered
{
  margin-top: -25px;
}

  ul.unordered, ul.checklist, ul.pluslist, ul.positivepointlist, ul.negativepointlist
, ol.ordered
, ul.unordered ul, ul.checklist ul, ul.pluslist ul, ul.positivepointlist ul, ul.negativepointlist ul
, ol.ordered ul
, ul.unordered ol, ul.checklist ol, ul.pluslist ol, ul.positivepointlist ol, ul.negativepointlist ol
, ol.ordered ol
{
  padding: 0;
  line-height: 150%;
  list-style-type: none;
}

  ul.unordered li, ul.checklist li, ul.pluslist li, ul.positivepointlist li, ul.negativepointlist li
, ol.ordered li
{
  position: relative;
  padding: 0 0 0 25px;
  min-height: 15px;
}

  ul.unordered li::before, ul.checklist li::before, ul.pluslist li::before, ul.positivepointlist li::before, ul.negativepointlist li::before
, ol.ordered li::before
{
  display: inline-block;
  position: absolute;
  left: 0;
  top: 2px;
}
  ol.ordered ul > li
, ul.unordered ul > li, ul.checklist ul > li, ul.pluslist ul > li, ul.positivepointlist ul > li, ul.negativepointlist ul > li
{
  padding-left: 25px;
}

  ul.unordered > li::before
, ul.unordered ul > li::before, ul.checklist ul > li::before, ul.pluslist ul > li::before, ul.positivepointlist ul > li::before, ul.negativepointlist ul > li::before
, ol.ordered ul > li::before
{
  content: "\25cf"; /* solid circle */
  text-align: center;
  font-size: 10px;
}

ul.checklist > li + li
{
  margin-top: 10px;
}
ul.pluslist > li + li
{
  margin-top: 5px;
}

ul.checklist > li::before, ul.pluslist > li::before, ul.positivepointlist > li::before, ul.negativepointlist > li::before
{
  font-family: 'Font Awesome 5 Pro' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-align: center;
  line-height: 1;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul.positivepointlist > li::before
{
  color: var(--bs-success);
  content: "\f058"; /* fa-check-circle */
  top: 4px;
}
ul.negativepointlist > li::before
{
  color: var(--bs-danger);
  content: "\f057"; /* fa-times-circle */
  top: 4px;
}
ul.checklist > li::before
{
  font-weight: 700;
  content: "\f00c"; /* fa-check */
  font-size: 18px;
  color: var(--bs-green);
}
ul.pluslist > li::before
{
  font-weight: 700;
  content: "\f067"; /* fa-plus */
  font-size: 18px;
  color: var(--bs-primary);
}

  ol.ordered
, ol.ordered ol
, ul.unordered ol, ul.checklist ol, ul.pluslist ol, ul.positivepointlist ol, ul.negativepointlist ol
{
  counter-reset: numlist;
}
  ol.ordered > li::before
, ol.ordered ol > li::before
, ul.unordered ol > li::before, ul.checklist ol > li::before, ul.pluslist ol > li::before, ul.positivepointlist ol > li::before, ul.negativepointlist ol > li::before
{
  counter-increment: numlist;
  content: counter(numlist, decimal) ".";
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 30px;
  max-width: 100%;
}
.wh-rtd__table
{
  border: 1px solid var(--bs-gray-300);
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  background-color: var(--bs-secondary-dark);
}

