/* use smaller paddings <= 750 */
.widget-reviewbanner {
  margin-bottom: 30px;
  background-color: var(--bs-light);
  font-weight: 600;
}
.widget-reviewbanner__inner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}
.widget-reviewbanner__instructions, .widget-reviewbanner__review {
  padding: clamp(24px, 3vw, 50px);
}
.widget-reviewbanner__instructions {
  flex: 1 1 auto;
  margin: 75px 0 50px;
  max-width: clamp(410px, 40vw, 560px);
  color: #fff;
  background-color: var(--bs-secondary-dark);
}
.widget-reviewbanner__instructions > * {
  margin-right: 75px;
}
.widget-reviewbanner__instructions h2 {
  color: inherit;
}
.widget-reviewbanner__review {
  width: 50%;
  flex: none;
  margin: 50px 0;
  position: relative;
  margin-left: -75px;
  max-width: clamp(300px, 30vw, 410px);
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.widget-reviewbanner__image {
  position: absolute;
  width: clamp(350px, 35vw, 450px);
  height: auto;
  right: 0;
  top: 25px;
  max-width: 100%;
}
@media (max-width: 1023px) {
  .widget-reviewbanner__inner {
    flex-flow: column;
  }
  .widget-reviewbanner__instructions {
    width: 100%;
    margin: 50px 0 0;
    max-width: 100%;
    margin-top: clamp(15px, 5vw, 50px);
  }
  .widget-reviewbanner__instructions > * {
    margin-right: 0;
  }
  .widget-reviewbanner__review {
    width: 100%;
    margin: 0 0 24px;
    margin-left: 0;
    max-width: 100%;
    border-radius: 0 0 10px 10px;
  }
  .widget-reviewbanner__image {
    position: relative;
    margin: -120px 15% 50px auto;
    top: auto;
    right: auto;
    margin-bottom: clamp(15px, 5vw, 50px);
  }
}