/* use smaller paddings <= 750 */
.widget-banner {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.widget-banner__link {
  display: block;
  text-decoration: none;
  color: inherit;
}
.widget-banner__image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 16px;
}
.widget-banner__image:has(+ .widget-banner__description) {
  border-radius: 16px 16px 0 0;
}
.widget-banner__description {
  padding: 15px 24px 15px;
}