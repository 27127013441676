/* use smaller paddings <= 750 */
.wh-form__imgedit {
  width: 150px;
  height: 200px;
  padding: 0;
  flex: none;
  background-color: #f1f1f1 !important;
}
.wh-form__imgedit:not(.wh-form__imgedit--hasimage)::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.wh-form__imgedit .wh-form__imgeditdelete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 7px;
  background-color: transparent;
  cursor: pointer;
}
.wh-form__imgedit .wh-form__imgeditdelete::before {
  width: 20px;
  height: 20px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 50%;
  font-weight: 300;
  color: var(--bs-dark);
  background-color: #fff;
  font-size: 16px;
  content: "\f057";
}
.wh-form__imgedit .wh-form__imgeditholder::before {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 15px;
  bottom: 0;
  left: 0;
  padding: 20px;
  content: "Drop of upload een afbeelding";
}
.wh-form__imgedit--hasimage::after,
.wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form__imgedit[data-wh-form-disabled]::after {
  opacity: 0.2;
}
.wh-form__imgedit[data-wh-form-disabled] .wh-form__imgeditholder {
  display: none;
}

.wh-form__imgeditholder::after {
  z-index: 1;
  content: "\f1ce";
  display: inline-block;
  position: absolute;
  display: none;
  width: 30px;
  top: 50%;
  left: 50%;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 30px;
  font-size: 30px;
  line-height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  opacity: 0.8;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

.wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form--uploading .wh-form__imgedit::after,
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}