/* use smaller paddings <= 750 */
.widget-threecol {
  margin-bottom: 30px;
}
.widget-threecol__columns {
  display: flex;
  justify-content: space-between;
}
.widget-threecol__columns__col {
  flex: none;
  width: calc(33.3333% - 24px);
}
.widget-threecol__columns__col > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .widget-threecol__columns {
    display: block;
  }
  .widget-threecol__columns__col {
    width: 100%;
  }
  .widget-threecol__columns__col + .widget-threecol__columns__col {
    margin-top: 30px;
  }
}